export default function SvgSelector({ name }) {
  switch (name) {
    case "close":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1310_5640"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1310_5640)">
            <path
              d="M6.4 18.6537L5.34625 17.5999L10.9463 11.9999L5.34625 6.39994L6.4 5.34619L12 10.9462L17.6 5.34619L18.6538 6.39994L13.0538 11.9999L18.6538 17.5999L17.6 18.6537L12 13.0537L6.4 18.6537Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "close-small":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1311_6617"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1311_6617)">
            <path
              d="M8.227 16.8365L7.1635 15.773L10.9365 12L7.1635 8.25198L8.227 7.18848L12 10.9615L15.748 7.18848L16.8115 8.25198L13.0385 12L16.8115 15.773L15.748 16.8365L12 13.0635L8.227 16.8365Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "instant-mix":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1311_6616"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1311_6616)">
            <path
              d="M5.173 19.75V12.6923H3.173V11.1923H8.673V12.6923H6.673V19.75H5.173ZM5.173 8.80775V4.25H6.673V8.80775H5.173ZM9.25 8.80775V7.30775H11.25V4.25H12.75V7.30775H14.75V8.80775H9.25ZM11.25 19.75V11.1923H12.75V19.75H11.25ZM17.327 19.75V16.6923H15.327V15.1923H20.827V16.6923H18.827V19.75H17.327ZM17.327 12.8077V4.25H18.827V12.8077H17.327Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "menu":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1312_6020"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1312_6020)">
            <path
              d="M3.5 17.6347V16.135H20.5V17.6347H3.5ZM3.5 12.7502V11.2502H20.5V12.7502H3.5ZM3.5 7.86547V6.36572H20.5V7.86547H3.5Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "arrow-back":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1312_6034"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1312_6034)">
            <path
              d="M16 21.6537L6.34625 11.9999L16 2.34619L17.4193 3.76544L9.1845 11.9999L17.4193 20.2344L16 21.6537Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "arrow-forward":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1312_6033"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1312_6033)">
            <path
              d="M8.0155 21.6537L6.59625 20.2344L14.8307 11.9999L6.59625 3.76544L8.0155 2.34619L17.6692 11.9999L8.0155 21.6537Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "search":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1311_6615"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1311_6615)">
            <path
              d="M19.5423 20.577L13.2615 14.296C12.7615 14.7088 12.1865 15.0319 11.5365 15.2653C10.8865 15.4986 10.2141 15.6153 9.51926 15.6153C7.81009 15.6153 6.36359 15.0235 5.17976 13.84C3.99592 12.6565 3.40401 11.2103 3.40401 9.50152C3.40401 7.79285 3.99576 6.34618 5.17926 5.16152C6.36276 3.97702 7.80892 3.38477 9.51776 3.38477C11.2264 3.38477 12.6731 3.97668 13.8578 5.16051C15.0423 6.34435 15.6345 7.79085 15.6345 9.50002C15.6345 10.2142 15.5147 10.8963 15.275 11.5463C15.0352 12.1963 14.7153 12.7616 14.3153 13.2423L20.596 19.523L19.5423 20.577ZM9.51926 14.1155C10.8078 14.1155 11.8991 13.6683 12.7933 12.774C13.6876 11.8798 14.1348 10.7885 14.1348 9.50002C14.1348 8.21152 13.6876 7.12018 12.7933 6.22601C11.8991 5.33168 10.8078 4.88452 9.51926 4.88452C8.23076 4.88452 7.13942 5.33168 6.24526 6.22601C5.35092 7.12018 4.90376 8.21152 4.90376 9.50002C4.90376 10.7885 5.35092 11.8798 6.24526 12.774C7.13942 13.6683 8.23076 14.1155 9.51926 14.1155Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "map":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1309_5730"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1309_5730)">
            <path
              d="M15 20.4616L9 18.3616L4.69625 20.0268C4.40142 20.141 4.12833 20.1083 3.877 19.9288C3.62567 19.7493 3.5 19.5038 3.5 19.1923V6.07708C3.5 5.87958 3.55292 5.70232 3.65875 5.54532C3.76442 5.38832 3.91283 5.27457 4.104 5.20407L9 3.53857L15 5.63857L19.3038 3.97332C19.5986 3.85916 19.8717 3.88699 20.123 4.05683C20.3743 4.22666 20.5 4.46416 20.5 4.76933V17.9616C20.5 18.1654 20.4423 18.3442 20.327 18.4981C20.2115 18.6519 20.0551 18.7641 19.8577 18.8346L15 20.4616ZM14.25 18.6271V6.92708L9.75 5.35383V17.0538L14.25 18.6271ZM15.75 18.6271L19 17.5501V5.70007L15.75 6.92708V18.6271ZM5 18.3001L8.25 17.0538V5.35383L5 6.45007V18.3001Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "location":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1446_559"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1446_559)">
            <path
              d="M12 21.5095C9.48333 19.3288 7.59616 17.2994 6.3385 15.4212C5.08083 13.5429 4.452 11.8185 4.452 10.248C4.452 7.94033 5.19841 6.07208 6.69125 4.64325C8.18425 3.21442 9.95383 2.5 12 2.5H12.4037C12.5384 2.5 12.6731 2.51025 12.8077 2.53075V4.04625C12.6731 4.02575 12.5418 4.01133 12.414 4.003C12.286 3.99467 12.148 3.9905 12 3.9905C10.2847 3.9905 8.84783 4.57767 7.6895 5.752C6.53116 6.92633 5.952 8.425 5.952 10.248C5.952 11.457 6.47091 12.8657 7.50875 14.474C8.54658 16.0823 10.0437 17.7622 12 19.5135C13.9435 17.775 15.4326 16.1096 16.4672 14.5173C17.5019 12.9249 18.0288 11.5243 18.048 10.3155V10.1923H19.548V10.3155C19.5288 11.8795 18.892 13.5942 17.6375 15.4595C16.383 17.325 14.5038 19.3417 12 21.5095ZM12.0017 11.8652C12.4992 11.8652 12.9246 11.6881 13.2777 11.3338C13.6311 10.9794 13.8077 10.5535 13.8077 10.056C13.8077 9.5585 13.6306 9.13308 13.2762 8.77975C12.9219 8.42658 12.4959 8.25 11.9982 8.25C11.5007 8.25 11.0754 8.42717 10.7222 8.7815C10.3689 9.13583 10.1922 9.56183 10.1922 10.0595C10.1922 10.557 10.3694 10.9823 10.7237 11.3355C11.0781 11.6887 11.5041 11.8652 12.0017 11.8652ZM18 8H19.5V5H22.5V3.5H19.5V0.5H18V3.5H15V5H18V8Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "photo":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1446_566"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1446_566)">
            <path
              d="M3.30775 20.5C2.80258 20.5 2.375 20.325 2.025 19.975C1.675 19.625 1.5 19.1974 1.5 18.6923V7.30775C1.5 6.80258 1.675 6.375 2.025 6.025C2.375 5.675 2.80258 5.5 3.30775 5.5H6.3615L8.2115 3.5H13.8077V5H8.8655L7.03075 7H3.30775C3.21792 7 3.14417 7.02883 3.0865 7.0865C3.02883 7.14417 3 7.21792 3 7.30775V18.6923C3 18.7821 3.02883 18.8558 3.0865 18.9135C3.14417 18.9712 3.21792 19 3.30775 19H18.6923C18.7821 19 18.8558 18.9712 18.9135 18.9135C18.9712 18.8558 19 18.7821 19 18.6923V10.1923H20.5V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H3.30775ZM19 7V5H17V3.5H19V1.5H20.5V3.5H22.5V5H20.5V7H19ZM11 17.1152C12.1475 17.1152 13.1202 16.7163 13.9183 15.9183C14.7163 15.1202 15.1152 14.1475 15.1152 13C15.1152 11.8525 14.7163 10.8798 13.9183 10.0818C13.1202 9.28375 12.1475 8.88475 11 8.88475C9.8525 8.88475 8.87975 9.28375 8.08175 10.0818C7.28375 10.8798 6.88475 11.8525 6.88475 13C6.88475 14.1475 7.28375 15.1202 8.08175 15.9183C8.87975 16.7163 9.8525 17.1152 11 17.1152ZM11 15.6155C10.2615 15.6155 9.641 15.3642 9.1385 14.8615C8.63583 14.359 8.3845 13.7385 8.3845 13C8.3845 12.2615 8.63583 11.641 9.1385 11.1385C9.641 10.6358 10.2615 10.3845 11 10.3845C11.7385 10.3845 12.359 10.6358 12.8615 11.1385C13.3642 11.641 13.6155 12.2615 13.6155 13C13.6155 13.7385 13.3642 14.359 12.8615 14.8615C12.359 15.3642 11.7385 15.6155 11 15.6155Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "text":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1446_573"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1446_573)">
            <path
              d="M16.25 20.725V17.725H13.25V16.225H16.25V13.225H17.75V16.225H20.75V17.725H17.75V20.725H16.25ZM4.25 17.75V16.25H11.2963C11.2654 16.5133 11.2542 16.7641 11.2625 17.0022C11.2708 17.2404 11.2904 17.4897 11.3213 17.75H4.25ZM4.25 13.75V12.25H13.727C13.4398 12.4525 13.1773 12.6788 12.9395 12.9288C12.7017 13.1788 12.4853 13.4525 12.2905 13.75H4.25ZM4.25 9.75V8.25H18.75V9.75H4.25ZM4.25 5.75V4.25H18.75V5.75H4.25Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "send":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1455_2020"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1455_2020)">
            <path
              d="M14.9712 18.5L13.9173 17.4402L17.627 13.7692H7.6635C6.50967 13.7692 5.52733 13.3686 4.7165 12.5673C3.9055 11.7661 3.5 10.7886 3.5 9.63475C3.5 8.48075 3.9055 7.50317 4.7165 6.702C5.52733 5.90067 6.50967 5.5 7.6635 5.5H8.14425V7H7.6635C6.925 7 6.2965 7.2545 5.778 7.7635C5.25933 8.2725 5 8.89625 5 9.63475C5 10.3731 5.25933 10.9968 5.778 11.5058C6.2965 12.0148 6.925 12.2692 7.6635 12.2692H17.627L13.9173 8.573L14.9712 7.529L20.5 13.0192L14.9712 18.5Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "chat_bubble":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1552_7880"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1552_7880)">
            <path
              d="M2.5 21.0385V4.30775C2.5 3.80258 2.675 3.375 3.025 3.025C3.375 2.675 3.80258 2.5 4.30775 2.5H19.6923C20.1974 2.5 20.625 2.675 20.975 3.025C21.325 3.375 21.5 3.80258 21.5 4.30775V15.6923C21.5 16.1974 21.325 16.625 20.975 16.975C20.625 17.325 20.1974 17.5 19.6923 17.5H6.0385L2.5 21.0385ZM5.4 16H19.6923C19.7693 16 19.8398 15.9679 19.9038 15.9038C19.9679 15.8398 20 15.7692 20 15.6923V4.30775C20 4.23075 19.9679 4.16025 19.9038 4.09625C19.8398 4.03208 19.7693 4 19.6923 4H4.30775C4.23075 4 4.16025 4.03208 4.09625 4.09625C4.03208 4.16025 4 4.23075 4 4.30775V17.3848L5.4 16Z"
              fill="#2A201C"
            />
          </g>
        </svg>
      );
    case "city":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1729_2160"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1729_2160)">
            <path
              d="M12.0017 11.8652C12.4992 11.8652 12.9246 11.6881 13.2777 11.3338C13.6311 10.9794 13.8077 10.5535 13.8077 10.056C13.8077 9.5585 13.6306 9.13308 13.2762 8.77975C12.9219 8.42658 12.4959 8.25 11.9982 8.25C11.5007 8.25 11.0754 8.42717 10.7222 8.7815C10.3689 9.13583 10.1922 9.56183 10.1922 10.0595C10.1922 10.557 10.3694 10.9823 10.7237 11.3355C11.0781 11.6887 11.5041 11.8652 12.0017 11.8652ZM12 19.5135C13.9563 17.7622 15.4534 16.0823 16.4912 14.474C17.5291 12.8657 18.048 11.457 18.048 10.248C18.048 8.425 17.4688 6.92633 16.3105 5.752C15.1522 4.57767 13.7153 3.9905 12 3.9905C10.2847 3.9905 8.84783 4.57767 7.6895 5.752C6.53116 6.92633 5.952 8.425 5.952 10.248C5.952 11.457 6.47091 12.8657 7.50875 14.474C8.54658 16.0823 10.0437 17.7622 12 19.5135ZM12 21.5095C9.48333 19.3288 7.59616 17.2994 6.3385 15.4212C5.08083 13.5429 4.452 11.8185 4.452 10.248C4.452 7.94033 5.19841 6.07208 6.69125 4.64325C8.18425 3.21442 9.95383 2.5 12 2.5C14.0462 2.5 15.8157 3.21442 17.3087 4.64325C18.8016 6.07208 19.548 7.94033 19.548 10.248C19.548 11.8185 18.9192 13.5429 17.6615 15.4212C16.4038 17.2994 14.5167 19.3288 12 21.5095Z"
              fill="#1C1B1F"
            />
          </g>
        </svg>
      );
    case "list":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1729_2332"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect
              width="24"
              height="24"
              transform="matrix(-1 0 0 1 24 0)"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_1729_2332)">
            <path
              d="M8.19225 20.5C8.60125 20.5 8.9535 20.3523 9.249 20.0568C9.5445 19.7613 9.69225 19.409 9.69225 19V14.8077C9.69225 14.3987 9.5445 14.0465 9.249 13.751C8.9535 13.4555 8.60125 13.3077 8.19225 13.3077H4C3.591 13.3077 3.23875 13.4555 2.94325 13.751C2.64775 14.0465 2.5 14.3987 2.5 14.8077V19C2.5 19.409 2.64775 19.7613 2.94325 20.0568C3.23875 20.3523 3.591 20.5 4 20.5H8.19225ZM8.19225 19H4V14.8077H8.19225V19ZM21.5 17.6538V16.1538H12.8845V17.6538H21.5ZM8.19225 10.6923C8.60125 10.6923 8.9535 10.5445 9.249 10.249C9.5445 9.9535 9.69225 9.60125 9.69225 9.19225V5C9.69225 4.591 9.5445 4.23875 9.249 3.94325C8.9535 3.64775 8.60125 3.5 8.19225 3.5H4C3.591 3.5 3.23875 3.64775 2.94325 3.94325C2.64775 4.23875 2.5 4.591 2.5 5V9.19225C2.5 9.60125 2.64775 9.9535 2.94325 10.249C3.23875 10.5445 3.591 10.6923 4 10.6923H8.19225ZM8.19225 9.19225H4V5H8.19225V9.19225ZM21.5 7.84625V6.34625H12.8845V7.84625H21.5Z"
              fill="#1C1B1F"
            />
          </g>
        </svg>
      );
    case "my_geo":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1763_1858"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#1C1B1F" />
          </mask>
          <g mask="url(#mask0_1763_1858)">
            <path
              d="M13.0155 20.1728L10.377 13.6035L3.80775 10.946L3.7885 9.93054L20.2115 3.76904L14.0307 20.1728H13.0155ZM12.1404 8.39592L17.6578 6.32279L13.0155 9.93054L6.623 10.469L8.5 8.99979L12.1404 8.39592Z"
              fill="#1C1B1F"
            />
          </g>
        </svg>
      );
    case "more":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1780_11962"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1780_11962)">
            <path
              d="M6.23075 13.5C5.81825 13.5 5.46517 13.3531 5.1715 13.0592C4.87767 12.7656 4.73075 12.4125 4.73075 12C4.73075 11.5875 4.87767 11.2344 5.1715 10.9408C5.46517 10.6469 5.81825 10.5 6.23075 10.5C6.64325 10.5 6.99642 10.6469 7.29025 10.9408C7.58392 11.2344 7.73075 11.5875 7.73075 12C7.73075 12.4125 7.58392 12.7656 7.29025 13.0592C6.99642 13.3531 6.64325 13.5 6.23075 13.5ZM12 13.5C11.5875 13.5 11.2344 13.3531 10.9408 13.0592C10.6469 12.7656 10.5 12.4125 10.5 12C10.5 11.5875 10.6469 11.2344 10.9408 10.9408C11.2344 10.6469 11.5875 10.5 12 10.5C12.4125 10.5 12.7656 10.6469 13.0593 10.9408C13.3531 11.2344 13.5 11.5875 13.5 12C13.5 12.4125 13.3531 12.7656 13.0593 13.0592C12.7656 13.3531 12.4125 13.5 12 13.5ZM17.7693 13.5C17.3568 13.5 17.0036 13.3531 16.7098 13.0592C16.4161 12.7656 16.2693 12.4125 16.2693 12C16.2693 11.5875 16.4161 11.2344 16.7098 10.9408C17.0036 10.6469 17.3568 10.5 17.7693 10.5C18.1818 10.5 18.5348 10.6469 18.8285 10.9408C19.1223 11.2344 19.2693 11.5875 19.2693 12C19.2693 12.4125 19.1223 12.7656 18.8285 13.0592C18.5348 13.3531 18.1818 13.5 17.7693 13.5Z"
              fill="#1C1B1F"
            />
          </g>
        </svg>
      );
    case "tg-white":
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="20" fill="#FCFBFA" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.7212 11.1308C26.9917 11.015 27.2877 10.975 27.5786 11.0151C27.8694 11.0552 28.1443 11.1739 28.3748 11.3587C28.6052 11.5436 28.7827 11.7879 28.8889 12.0663C28.995 12.3446 29.0259 12.6468 28.9782 12.9414L26.4957 28.2601C26.2549 29.7378 24.6611 30.5852 23.329 29.8491C22.2147 29.2334 20.5597 28.2846 19.0711 27.2947C18.3267 26.7992 16.0467 25.2124 16.3269 24.0833C16.5677 23.1179 20.3988 19.49 22.588 17.3331C23.4472 16.4857 23.0554 15.9969 22.0407 16.7764C19.5209 18.7117 15.4753 21.6547 14.1377 22.4832C12.9578 23.2136 12.3426 23.3384 11.607 23.2136C10.2651 22.9865 9.0205 22.6346 8.00471 22.2059C6.63209 21.6269 6.69886 19.7072 8.00362 19.1482L26.7212 11.1308Z"
            fill="#303E3E"
          />
        </svg>
      );
    case "vk-white":
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="20" fill="#FCFBFA" />
          <path
            d="M30.0355 27.9988H27.4077C26.4139 27.9988 26.1145 27.152 24.333 25.2766C22.7762 23.698 22.1186 23.5017 21.7248 23.5017C21.1801 23.5017 21.0316 23.6592 21.0316 24.4479V26.9339C21.0316 27.6063 20.8243 28 19.1546 28C17.5347 27.8855 15.9638 27.3678 14.5714 26.4894C13.1789 25.6111 12.0048 24.3973 11.1456 22.948C9.10547 20.2767 7.68598 17.1414 7 13.7915C7 13.3772 7.1497 13.0016 7.90051 13.0016H10.526C11.2008 13.0016 11.4438 13.3178 11.7087 14.0484C12.9834 17.9954 15.1587 21.4288 16.0419 21.4288C16.3805 21.4288 16.5279 21.2713 16.5279 20.3833V16.3175C16.4162 14.4627 15.48 14.3064 15.48 13.6353C15.4918 13.4582 15.5688 13.2929 15.6946 13.1746C15.8204 13.0563 15.985 12.9943 16.1536 13.0016H20.2808C20.845 13.0016 21.0316 13.2972 21.0316 14.0072V19.4953C21.0316 20.0877 21.2734 20.284 21.4438 20.284C21.7824 20.284 22.0426 20.0877 22.6633 19.4359C23.9937 17.7289 25.0807 15.8271 25.8899 13.7903C25.9728 13.5458 26.1294 13.3365 26.3358 13.1943C26.5423 13.0521 26.7873 12.9849 27.0334 13.0029H29.6601C30.4478 13.0029 30.6147 13.4172 30.4478 14.0084C29.4924 16.2599 28.3103 18.3971 26.9217 20.3833C26.6384 20.8376 26.5256 21.0739 26.9217 21.6069C27.182 22.0213 28.1032 22.8305 28.7216 23.5998C29.6221 24.5448 30.3694 25.6376 30.9349 26.8345C31.1606 27.6051 30.7852 27.9988 30.0355 27.9988Z"
            fill="#303E3E"
          />
        </svg>
      );
    case "ontop":
      return (
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="46" height="46" rx="23" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.5074 13.6069V34H22.4926V13.6069L13.7176 20.8653L13 20.2717L23 12L33 20.2717L32.2824 20.8653L23.5074 13.6069Z"
            fill="#2A201C"
          />
        </svg>
      );
  }
}
