export default function Logo({ name }) {
  switch (name) {
    case "logo-main-orange":
      return (
        <svg
          width="124"
          height="58"
          viewBox="0 0 124 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.2592 19.2671C16.5998 19.2721 15.946 19.1459 15.3355 18.8956C14.7589 18.6577 14.2348 18.308 13.7928 17.8666C13.351 17.4233 13.0047 16.8935 12.7755 16.3101C12.5267 15.6827 12.4011 15.0129 12.4055 14.3375C12.4006 13.6634 12.5262 12.9947 12.7755 12.3686C13.0047 11.7852 13.351 11.2554 13.7928 10.8121C14.2328 10.3683 14.7575 10.0183 15.3355 9.78311C15.9455 9.53116 16.5996 9.40483 17.2592 9.41163C17.9199 9.40453 18.5754 9.53086 19.1866 9.78311C19.7645 10.0183 20.2893 10.3683 20.7293 10.8121C21.1722 11.2561 21.5208 11.7856 21.754 12.3686C22.0063 12.9938 22.1321 13.6631 22.124 14.3375C22.1321 15.0132 22.0064 15.6837 21.754 16.3101C21.5208 16.8932 21.1722 17.4227 20.7293 17.8666C20.2873 18.308 19.7631 18.6577 19.1866 18.8956C18.5749 19.1462 17.9198 19.2724 17.2592 19.2671ZM17.5477 18.5242C18.099 18.5364 18.64 18.374 19.0941 18.0598C19.5531 17.7291 19.9127 17.278 20.1336 16.7559C20.3947 16.1282 20.5208 15.4522 20.5036 14.7722C20.521 13.9303 20.3635 13.094 20.0412 12.3166C19.7755 11.6704 19.3425 11.1074 18.7871 10.6858C18.2781 10.3062 17.6601 10.1028 17.0261 10.1063C16.4728 10.0961 15.9295 10.2555 15.4687 10.5632C15.0006 10.8867 14.6326 11.336 14.4069 11.8597C14.1428 12.4878 14.0166 13.1657 14.037 13.8472C14.0185 14.6923 14.1786 15.5317 14.5068 16.3101C14.7767 16.9591 15.2148 17.5235 15.7757 17.9446C16.2895 18.3333 16.9155 18.5433 17.5588 18.5427L17.5477 18.5242Z"
            fill="#F75307"
          />
          <path
            d="M28.6836 19.2697C27.7896 19.2944 26.9046 19.0844 26.1161 18.6604C25.4135 18.2753 24.8338 17.6983 24.4441 16.9962C24.0421 16.2603 23.8381 15.4319 23.8521 14.5927C23.8478 13.9141 23.9852 13.2421 24.2554 12.6201C24.5237 12.0078 24.8997 11.4491 25.3652 10.9707C25.8256 10.4925 26.3715 10.1056 26.9744 9.83022C27.5824 9.55493 28.2423 9.4143 28.9092 9.41788C29.3244 9.41246 29.7392 9.44478 30.1486 9.51446C30.456 9.56977 30.758 9.65181 31.0512 9.75964C31.3139 9.85994 31.5839 9.97882 31.8503 10.1126L31.9391 12.6944H31.6801L30.685 10.811C30.409 10.6084 30.1112 10.4376 29.7972 10.302C29.5137 10.1936 29.2125 10.1394 28.9092 10.1423C28.2737 10.1336 27.6502 10.316 27.1188 10.6661C26.5806 11.024 26.1496 11.5222 25.872 12.1074C25.5577 12.7692 25.4019 13.4956 25.417 14.2286C25.3969 15.0216 25.547 15.8097 25.8572 16.5392C26.1059 17.1379 26.5191 17.6533 27.0484 18.0252C27.5366 18.3594 28.1149 18.5357 28.7057 18.5304C29.135 18.5422 29.5627 18.4741 29.9673 18.3298C30.2899 18.2048 30.5976 18.0441 30.8848 17.8506L31.8503 16.2272H32.0982L31.9243 18.5601C31.4887 18.7761 31.0341 18.9514 30.5666 19.0839C29.9497 19.2285 29.3167 19.291 28.6836 19.2697Z"
            fill="#F75307"
          />
          <path
            d="M35.8848 19.1252V18.8949L37.3423 18.3154V10.3619H35.0635L34.0387 12.55H33.8093V9.57808H42.3773V12.55H42.1479L41.138 10.3619H38.8443V18.3154L40.3241 18.8949V19.1252H35.8848Z"
            fill="#F75307"
          />
          <path
            d="M48.6321 19.2671C47.9715 19.2721 47.3165 19.1459 46.7047 18.8956C46.1282 18.6577 45.604 18.308 45.1621 17.8666C44.7215 17.4223 44.3754 16.8928 44.1447 16.3101C43.8959 15.6827 43.7702 15.0128 43.7748 14.3375C43.7699 13.6633 43.8955 12.9946 44.1447 12.3686C44.3754 11.7859 44.7215 11.2564 45.1621 10.8121C45.6021 10.3683 46.1268 10.0183 46.7047 9.78309C47.316 9.53113 47.9714 9.40481 48.6321 9.41161C49.2929 9.40481 49.9482 9.53113 50.5595 9.78309C51.1365 10.0202 51.6608 10.3699 52.1022 10.8121C52.5426 11.258 52.8909 11.787 53.1269 12.3686C53.3775 12.9942 53.5032 13.6632 53.4969 14.3375C53.5031 15.013 53.3775 15.6832 53.1269 16.3101C52.8909 16.8917 52.5426 17.4207 52.1022 17.8666C51.6592 18.3067 51.1353 18.6562 50.5595 18.8956C49.9477 19.1459 49.2927 19.2721 48.6321 19.2671ZM48.9207 18.5241C49.4758 18.5436 50.0227 18.3849 50.4819 18.0709C50.9418 17.7412 51.3016 17.2898 51.5214 16.767C51.7841 16.1398 51.9103 15.4634 51.8914 14.7833C51.91 13.9417 51.7538 13.1054 51.4326 12.3278C51.1643 11.682 50.7303 11.1193 50.1748 10.6969C49.6661 10.3168 49.0479 10.1134 48.4138 10.1174C47.8606 10.1077 47.3174 10.2671 46.8564 10.5744C46.3894 10.8981 46.0227 11.3474 45.7984 11.8708C45.5342 12.4989 45.408 13.1768 45.4284 13.8583C45.4119 14.7032 45.5719 15.5423 45.8983 16.3212C46.1682 16.9702 46.6063 17.5346 47.1672 17.9558C47.6772 18.3343 48.2939 18.5398 48.9281 18.5427L48.9207 18.5241Z"
            fill="#F75307"
          />
          <path
            d="M55.1611 19.1238V18.8935L56.3302 18.314V10.3753L55.1611 9.79583V9.56179H59.2009C60.1516 9.51803 61.0964 9.7337 61.9348 10.1859C62.2706 10.3868 62.5448 10.677 62.7269 11.0244C62.909 11.3718 62.9921 11.763 62.9669 12.1548C62.9699 12.6785 62.7757 13.1841 62.4231 13.5701C62.0165 14.0102 61.5083 14.3432 60.9434 14.5397C60.2693 14.7865 59.5557 14.9061 58.8384 14.8926H57.8284V18.314L59.1565 18.8935V19.1238H55.1611ZM57.8432 10.3605V14.1274H58.953C59.5921 14.1648 60.2245 13.9798 60.7436 13.6036C60.949 13.4493 61.115 13.2483 61.2282 13.0171C61.3414 12.786 61.3985 12.5312 61.3947 12.2736C61.4186 11.9935 61.3706 11.7119 61.2553 11.4557C61.14 11.1994 60.9613 10.9772 60.7362 10.81C60.1667 10.4762 59.5113 10.3198 58.8531 10.3605H57.8432Z"
            fill="#F75307"
          />
          <path
            d="M69.2984 19.2671C68.6391 19.2721 67.9852 19.1459 67.3747 18.8956C66.7981 18.6577 66.274 18.308 65.832 17.8666C65.3902 17.4233 65.0439 16.8935 64.8147 16.3101C64.5659 15.6827 64.4402 15.0129 64.4447 14.3375C64.4398 13.6634 64.5654 12.9947 64.8147 12.3686C65.0439 11.7852 65.3902 11.2554 65.832 10.8121C66.272 10.3683 66.7967 10.0183 67.3747 9.78311C67.9846 9.53116 68.6389 9.40483 69.2984 9.41163C69.9592 9.40453 70.6146 9.53086 71.2258 9.78311C71.8044 10.0192 72.3301 10.3691 72.7722 10.8121C73.2128 11.2572 73.56 11.7865 73.7932 12.3686C74.0455 12.9938 74.1713 13.6631 74.1632 14.3375C74.1713 15.0132 74.0455 15.6837 73.7932 16.3101C73.56 16.8923 73.2128 17.4216 72.7722 17.8666C72.3281 18.3072 71.8029 18.6567 71.2258 18.8956C70.6141 19.1462 69.959 19.2724 69.2984 19.2671ZM69.5906 18.5242C70.1406 18.5359 70.6805 18.3734 71.1333 18.0598C71.5923 17.7291 71.9519 17.278 72.1728 16.7559C72.4339 16.1282 72.56 15.4522 72.5428 14.7722C72.5602 13.9303 72.4027 13.094 72.0803 12.3166C71.8146 11.6704 71.3817 11.1074 70.8263 10.6858C70.3173 10.3062 69.6993 10.1028 69.0653 10.1063C68.5121 10.0966 67.9689 10.256 67.5079 10.5632C67.0398 10.8867 66.6718 11.336 66.4461 11.8597C66.182 12.4878 66.0558 13.1657 66.0761 13.8472C66.0577 14.6923 66.2178 15.5317 66.546 16.3101C66.8159 16.9591 67.2541 17.5235 67.815 17.9446C68.3268 18.3318 68.9498 18.5417 69.5906 18.5427V18.5242Z"
            fill="#F75307"
          />
          <path
            d="M81.406 14.2439L77.2219 19.1252H75.0023V18.8949L75.9419 18.5234L79.9669 13.8984L76.3747 10.2207L75.2908 9.81581V9.58178H78.6203V9.81581L77.5808 10.321L81.406 14.2439ZM80.2666 19.1252V18.8949L81.4207 18.3154V10.3768L80.2666 9.79724V9.5632H84.0733V9.79724L82.919 10.3768V18.3154L84.0733 18.8949V19.1252H80.2666ZM82.9338 14.2439L86.7591 10.3025L85.7195 9.79724V9.5632H88.964V9.79724L87.8838 10.2022L84.3767 13.7796L88.5607 18.516L89.456 18.8875V19.1178H87.1068L82.9338 14.2439Z"
            fill="#F75307"
          />
          <path
            d="M90.3773 19.1252V18.8949L91.5463 18.3154V10.3768L90.3773 9.79724V9.5632H94.2024V9.79724L93.0631 10.3768V13.7944H97.7502V10.3768L96.596 9.79724V9.5632H100.425V9.79724L99.2559 10.3768V18.3154L100.425 18.8949V19.1252H96.5997V18.8949L97.7539 18.3154V14.6005H93.0668V18.3154L94.2061 18.8949V19.1252H90.3773Z"
            fill="#F75307"
          />
          <path
            d="M106.929 19.2671C106.268 19.2724 105.613 19.1462 105.001 18.8957C104.425 18.6577 103.9 18.308 103.458 17.8666C103.018 17.4223 102.672 16.8928 102.441 16.3101C102.192 15.6827 102.067 15.0129 102.071 14.3375C102.066 13.6634 102.192 12.9947 102.441 12.3687C102.672 11.786 103.018 11.2564 103.458 10.8121C103.898 10.3683 104.423 10.0183 105.001 9.78312C105.612 9.53086 106.268 9.40453 106.929 9.41163C107.588 9.40453 108.242 9.53086 108.852 9.78312C109.431 10.0192 109.957 10.3691 110.399 10.8121C110.839 11.258 111.187 11.787 111.423 12.3687C111.674 12.9943 111.8 13.6632 111.793 14.3375C111.8 15.013 111.674 15.6832 111.423 16.3101C111.187 16.8917 110.839 17.4207 110.399 17.8666C109.955 18.3072 109.429 18.6567 108.852 18.8957C108.242 19.1463 107.588 19.2725 106.929 19.2671ZM107.217 18.5242C107.767 18.5359 108.307 18.3734 108.76 18.0598C109.22 17.7301 109.58 17.2787 109.799 16.7559C110.062 16.1287 110.188 15.4523 110.169 14.7722C110.188 13.9305 110.032 13.0943 109.711 12.3166C109.442 11.6709 109.008 11.1082 108.453 10.6858C107.944 10.3057 107.326 10.1023 106.692 10.1063C106.139 10.0966 105.595 10.256 105.134 10.5632C104.666 10.8867 104.298 11.336 104.073 11.8597C103.81 12.4883 103.684 13.1658 103.703 13.8472C103.684 14.6923 103.844 15.5317 104.172 16.3101C104.442 16.9591 104.881 17.5235 105.441 17.9446C105.953 18.3318 106.576 18.5417 107.217 18.5427V18.5242Z"
            fill="#F75307"
          />
          <path
            d="M21.7297 48.4377V47.8433L24.9482 46.2831V25.9628H18.4002V29.009C18.4002 32.4514 18.3139 35.2252 18.1412 37.3303C18.0338 38.9641 17.8236 40.5895 17.5122 42.1967C17.3553 43.0507 17.1072 43.8851 16.7723 44.6857C16.3158 45.718 15.6478 46.642 14.8117 47.3975C14.199 47.9388 13.4715 48.3326 12.6845 48.5492C12.0622 48.7126 11.4224 48.7999 10.7793 48.8092C10.2145 48.8092 9.86309 48.7349 9.72497 48.5863C9.56134 48.3335 9.48946 48.0317 9.52149 47.7319V44.3514L9.7434 44.1285H10.0764C10.9271 44.1399 11.7606 43.8869 12.4625 43.4041C13.2757 42.7777 13.8975 41.9347 14.2568 40.9709C14.8393 39.4928 15.2249 37.9439 15.4036 36.3644C15.7041 33.962 15.8401 31.5416 15.8105 29.1205V26.0743L12.555 24.514V23.9197H35.1956V24.514L31.9771 26.0743V46.2831L35.1956 47.8433V48.4377H21.7297Z"
            fill="#F75307"
          />
          <path
            d="M41.116 48.4384V47.8441L44.4086 46.2838V26.075L41.116 24.5148V23.9204H54.2861V24.5148L51.4375 26.075V34.7901H54.323C54.4857 32.7102 55.1802 30.7077 56.3393 28.9763C57.4722 27.2943 59.0041 25.9218 60.7971 24.9828C62.6941 23.9996 64.8036 23.502 66.9382 23.5341C68.6669 23.516 70.3823 23.8378 71.9879 24.4813C73.4906 25.0878 74.8606 25.9838 76.0203 27.1189C77.1729 28.2552 78.0852 29.6135 78.7024 31.1123C79.3557 32.7151 79.6827 34.4329 79.6642 36.1646C79.6835 37.8963 79.3565 39.6143 78.7024 41.2167C78.0871 42.7166 77.1746 44.0753 76.0203 45.2102C74.8615 46.3465 73.4913 47.2427 71.9879 47.8478C70.3823 48.4913 68.6669 48.8131 66.9382 48.7951C64.7333 48.8294 62.556 48.2988 60.6121 47.2534C58.7676 46.2569 57.2173 44.7898 56.1173 42.9999C54.9785 41.1391 54.3352 39.0156 54.2491 36.8332H51.4375V46.2689L54.2861 47.8292V48.4236L41.116 48.4384ZM62.0401 45.6449C63.3029 46.373 64.7493 46.2108 66.3796 45.1582C68.0098 44.1057 69.5525 42.3114 71.0076 39.7754C72.0805 37.9811 72.8998 36.0457 73.4418 34.0248C73.8784 32.2441 73.961 30.7124 73.6897 29.4295C73.4184 28.1467 72.824 27.2365 71.9065 26.6991C71.493 26.4704 71.0362 26.3318 70.5658 26.2921C70.0954 26.2524 69.6221 26.3126 69.1764 26.4688C67.9831 26.8752 66.8993 27.5528 66.0096 28.4488C64.7884 29.6457 63.7488 31.0163 62.9243 32.5166C61.8472 34.3171 61.0302 36.262 60.4975 38.2932C60.0634 40.0862 59.9832 41.6266 60.257 42.9144C60.527 44.2035 61.1227 45.1099 62.0401 45.6449Z"
            fill="#F75307"
          />
          <path
            d="M84.843 48.4377V47.8433L88.1356 46.2831V26.0743L84.843 24.514V23.9197H98.457V24.514L95.1645 26.0743V46.2831L98.457 47.8433V48.4377H84.843ZM95.1645 36.1787L105.264 25.9257L102.415 24.514V23.9197H111.738V24.514L109.185 25.6656L101.38 33.0954L110.739 46.6917L113.551 47.8433V48.4377H103.451L95.1645 36.1787Z"
            fill="#F75307"
          />
        </svg>
      );
    case "logo-main-black":
      return (
        <svg
          width="124"
          height="58"
          viewBox="0 0 124 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.259 19.2669C16.5997 19.2719 15.9458 19.1457 15.3353 18.8954C14.7588 18.6575 14.2347 18.3078 13.7927 17.8664C13.3509 17.4231 13.0046 16.8933 12.7754 16.3099C12.5266 15.6825 12.4009 15.0126 12.4054 14.3373C12.4005 13.6631 12.5261 12.9944 12.7754 12.3684C13.0046 11.785 13.3509 11.2552 13.7927 10.8119C14.2327 10.3681 14.7574 10.0181 15.3353 9.7829C15.9453 9.53094 16.5995 9.40462 17.259 9.41142C17.9198 9.40432 18.5752 9.53065 19.1864 9.7829C19.7644 10.0181 20.2892 10.3681 20.7292 10.8119C21.172 11.2558 21.5206 11.7854 21.7539 12.3684C22.0062 12.9935 22.132 13.6629 22.1239 14.3373C22.132 15.013 22.0062 15.6835 21.7539 16.3099C21.5206 16.893 21.172 17.4225 20.7292 17.8664C20.2872 18.3078 19.763 18.6575 19.1864 18.8954C18.5747 19.146 17.9196 19.2722 17.259 19.2669ZM17.5476 18.5239C18.0989 18.5362 18.6399 18.3737 19.094 18.0596C19.5529 17.7289 19.9126 17.2778 20.1335 16.7557C20.3946 16.128 20.5207 15.452 20.5035 14.7719C20.5209 13.9301 20.3634 13.0938 20.041 12.3164C19.7753 11.6702 19.3424 11.1072 18.787 10.6856C18.278 10.306 17.66 10.1026 17.026 10.1061C16.4727 10.0959 15.9294 10.2553 15.4686 10.563C15.0004 10.8865 14.6325 11.3358 14.4068 11.8595C14.1427 12.4876 14.0165 13.1655 14.0368 13.847C14.0184 14.692 14.1785 15.5315 14.5067 16.3099C14.7766 16.9588 15.2147 17.5233 15.7756 17.9444C16.2894 18.3331 16.9154 18.5431 17.5587 18.5425L17.5476 18.5239Z"
            fill="#2A201C"
          />
          <path
            d="M28.6837 19.2699C27.7897 19.2946 26.9047 19.0846 26.1163 18.6606C25.4136 18.2755 24.8339 17.6985 24.4442 16.9964C24.0422 16.2605 23.8382 15.4321 23.8522 14.5929C23.8479 13.9143 23.9853 13.2423 24.2555 12.6203C24.5239 12.008 24.8998 11.4493 25.3653 10.9709C25.8257 10.4926 26.3716 10.1057 26.9746 9.83041C27.5825 9.55511 28.2424 9.41448 28.9094 9.41806C29.3245 9.41265 29.7394 9.44497 30.1487 9.51464C30.4561 9.56996 30.7581 9.65199 31.0514 9.75982C31.314 9.86012 31.584 9.979 31.8504 10.1127L31.9392 12.6946H31.6802L30.6851 10.8111C30.4091 10.6085 30.1113 10.4378 29.7973 10.3022C29.5138 10.1938 29.2126 10.1396 28.9094 10.1425C28.2738 10.1338 27.6503 10.3162 27.1189 10.6662C26.5807 11.0241 26.1497 11.5224 25.8721 12.1076C25.5579 12.7694 25.4021 13.4958 25.4171 14.2288C25.397 15.0218 25.5472 15.8099 25.8573 16.5394C26.1061 17.1381 26.5192 17.6535 27.0485 18.0254C27.5367 18.3596 28.115 18.5359 28.7059 18.5306C29.1351 18.5423 29.5628 18.4743 29.9674 18.33C30.2901 18.205 30.5978 18.0443 30.8849 17.8508L31.8504 16.2274H32.0983L31.9245 18.5603C31.4888 18.7762 31.0343 18.9516 30.5667 19.0841C29.9499 19.2287 29.3168 19.2911 28.6837 19.2699Z"
            fill="#2A201C"
          />
          <path
            d="M35.885 19.1253V18.895L37.3426 18.3155V10.362H35.0637L34.039 12.55H33.8096V9.57812H42.3775V12.55H42.1481L41.1382 10.362H38.8446V18.3155L40.3243 18.895V19.1253H35.885Z"
            fill="#2A201C"
          />
          <path
            d="M48.6319 19.2669C47.9713 19.2719 47.3163 19.1457 46.7045 18.8954C46.128 18.6575 45.6038 18.3078 45.1618 17.8664C44.7212 17.4221 44.3751 16.8926 44.1445 16.3099C43.8956 15.6825 43.77 15.0126 43.7746 14.3373C43.7696 13.6631 43.8953 12.9944 44.1445 12.3684C44.3751 11.7857 44.7212 11.2562 45.1618 10.8119C45.6018 10.3681 46.1265 10.0181 46.7045 9.78288C47.3158 9.53092 47.9711 9.4046 48.6319 9.41139C49.2926 9.4046 49.948 9.53092 50.5593 9.78288C51.1363 10.02 51.6606 10.3697 52.1019 10.8119C52.5424 11.2578 52.8906 11.7868 53.1267 12.3684C53.3773 12.994 53.503 13.663 53.4966 14.3373C53.5029 15.0128 53.3772 15.683 53.1267 16.3099C52.8906 16.8915 52.5424 17.4205 52.1019 17.8664C51.6589 18.3065 51.135 18.656 50.5593 18.8954C49.9475 19.1457 49.2925 19.2719 48.6319 19.2669ZM48.9205 18.5239C49.4756 18.5434 50.0225 18.3846 50.4816 18.0707C50.9415 17.741 51.3014 17.2896 51.5212 16.7668C51.7839 16.1396 51.91 15.4632 51.8911 14.7831C51.9097 13.9414 51.7535 13.1052 51.4324 12.3276C51.1641 11.6818 50.73 11.1191 50.1745 10.6967C49.6658 10.3166 49.0477 10.1132 48.4136 10.1172C47.8603 10.1075 47.3172 10.2669 46.8561 10.5741C46.3891 10.8979 46.0225 11.3472 45.7981 11.8706C45.5339 12.4987 45.4077 13.1766 45.4282 13.8581C45.4116 14.703 45.5717 15.5421 45.898 16.321C46.1679 16.97 46.6061 17.5344 47.1669 17.9556C47.6769 18.334 48.2937 18.5396 48.9279 18.5425L48.9205 18.5239Z"
            fill="#2A201C"
          />
          <path
            d="M55.1611 19.1237V18.8934L56.3302 18.3139V10.3753L55.1611 9.79574V9.5617H59.201C60.1517 9.51794 61.0964 9.73361 61.9348 10.1858C62.2707 10.3868 62.5448 10.6769 62.7269 11.0243C62.9091 11.3717 62.9921 11.7629 62.967 12.1547C62.97 12.6784 62.7757 13.184 62.4232 13.57C62.0165 14.0101 61.5084 14.3431 60.9434 14.5396C60.2694 14.7864 59.5558 14.906 58.8385 14.8925H57.8285V18.3139L59.1566 18.8934V19.1237H55.1611ZM57.8432 10.3604V14.1273H58.9531C59.5921 14.1647 60.2246 13.9797 60.7436 13.6035C60.949 13.4492 61.1151 13.2482 61.2283 13.0171C61.3415 12.7859 61.3985 12.5311 61.3947 12.2736C61.4186 11.9934 61.3707 11.7118 61.2554 11.4556C61.1401 11.1994 60.9614 10.9771 60.7362 10.8099C60.1667 10.4761 59.5113 10.3197 58.8532 10.3604H57.8432Z"
            fill="#2A201C"
          />
          <path
            d="M69.2982 19.2669C68.6388 19.2719 67.9849 19.1457 67.3744 18.8954C66.7979 18.6575 66.2737 18.3078 65.8318 17.8664C65.3899 17.4231 65.0437 16.8933 64.8144 16.3099C64.5656 15.6825 64.44 15.0126 64.4445 14.3373C64.4395 13.6631 64.5652 12.9944 64.8144 12.3684C65.0437 11.785 65.3899 11.2552 65.8318 10.8119C66.2718 10.3681 66.7964 10.0181 67.3744 9.7829C67.9844 9.53094 68.6387 9.40462 69.2982 9.41142C69.959 9.40432 70.6144 9.53065 71.2256 9.7829C71.8042 10.019 72.3299 10.3689 72.7719 10.8119C73.2125 11.257 73.5597 11.7862 73.793 12.3684C74.0453 12.9935 74.1711 13.6629 74.1629 14.3373C74.171 15.013 74.0453 15.6835 73.793 16.3099C73.5597 16.8921 73.2125 17.4214 72.7719 17.8664C72.3279 18.307 71.8027 18.6565 71.2256 18.8954C70.6139 19.146 69.9588 19.2722 69.2982 19.2669ZM69.5904 18.5239C70.1404 18.5357 70.6802 18.3732 71.1331 18.0596C71.5921 17.7289 71.9516 17.2778 72.1726 16.7557C72.4337 16.128 72.5597 15.452 72.5425 14.7719C72.56 13.9301 72.4025 13.0938 72.0801 12.3164C71.8144 11.6702 71.3814 11.1072 70.826 10.6856C70.3171 10.306 69.6991 10.1026 69.0651 10.1061C68.5118 10.0964 67.9687 10.2557 67.5076 10.563C67.0395 10.8865 66.6715 11.3358 66.4458 11.8595C66.1817 12.4876 66.0556 13.1655 66.0759 13.847C66.0574 14.692 66.2176 15.5315 66.5457 16.3099C66.8156 16.9588 67.2539 17.5233 67.8147 17.9444C68.3265 18.3316 68.9496 18.5414 69.5904 18.5425V18.5239Z"
            fill="#2A201C"
          />
          <path
            d="M81.4057 14.2437L77.2216 19.125H75.002V18.8947L75.9416 18.5232L79.9666 13.8982L76.3744 10.2205L75.2905 9.8156V9.58157H78.62V9.8156L77.5805 10.3208L81.4057 14.2437ZM80.2663 19.125V18.8947L81.4204 18.3152V10.3765L80.2663 9.79703V9.56299H84.073V9.79703L82.9187 10.3765V18.3152L84.073 18.8947V19.125H80.2663ZM82.9335 14.2437L86.7588 10.3022L85.7192 9.79703V9.56299H88.9637V9.79703L87.8835 10.2019L84.3764 13.7793L88.5604 18.5158L89.4557 18.8873V19.1176H87.1065L82.9335 14.2437Z"
            fill="#2A201C"
          />
          <path
            d="M90.3774 19.125V18.8947L91.5464 18.3152V10.3765L90.3774 9.79703V9.56299H94.2026V9.79703L93.0632 10.3765V13.7942H97.7504V10.3765L96.5961 9.79703V9.56299H100.425V9.79703L99.2561 10.3765V18.3152L100.425 18.8947V19.125H96.5998V18.8947L97.7541 18.3152V14.6003H93.0669V18.3152L94.2063 18.8947V19.125H90.3774Z"
            fill="#2A201C"
          />
          <path
            d="M106.929 19.2669C106.268 19.2722 105.613 19.146 105.001 18.8954C104.425 18.6575 103.901 18.3078 103.459 17.8664C103.018 17.4221 102.672 16.8926 102.441 16.3099C102.193 15.6825 102.067 15.0126 102.071 14.3373C102.066 13.6632 102.192 12.9944 102.441 12.3684C102.672 11.7858 103.018 11.2562 103.459 10.8119C103.899 10.3681 104.423 10.0181 105.001 9.7829C105.613 9.53065 106.268 9.40432 106.929 9.41142C107.588 9.40431 108.243 9.53065 108.853 9.7829C109.431 10.019 109.957 10.3689 110.399 10.8119C110.839 11.2578 111.188 11.7868 111.424 12.3684C111.674 12.994 111.8 13.663 111.794 14.3373C111.8 15.0128 111.674 15.683 111.424 16.3099C111.188 16.8915 110.839 17.4205 110.399 17.8664C109.955 18.307 109.43 18.6565 108.853 18.8954C108.242 19.146 107.588 19.2723 106.929 19.2669ZM107.217 18.524C107.767 18.5357 108.307 18.3732 108.76 18.0596C109.22 17.7298 109.58 17.2785 109.8 16.7557C110.062 16.1285 110.188 15.4521 110.17 14.7719C110.188 13.9303 110.032 13.0941 109.711 12.3164C109.443 11.6707 109.008 11.108 108.453 10.6856C107.944 10.3055 107.326 10.1021 106.692 10.1061C106.139 10.0964 105.596 10.2557 105.135 10.563C104.666 10.8865 104.299 11.3358 104.073 11.8595C103.811 12.4881 103.684 13.1656 103.703 13.847C103.684 14.692 103.845 15.5315 104.173 16.3099C104.443 16.9588 104.881 17.5233 105.442 17.9444C105.953 18.3316 106.577 18.5414 107.217 18.5425V18.524Z"
            fill="#2A201C"
          />
          <path
            d="M21.7293 48.4375V47.8431L24.9478 46.2829V25.9626H18.3998V29.0088C18.3998 32.4512 18.3135 35.225 18.1408 37.3301C18.0334 38.9639 17.8233 40.5893 17.5119 42.1965C17.3549 43.0504 17.1069 43.8849 16.772 44.6855C16.3155 45.7178 15.6475 46.6418 14.8113 47.3973C14.1987 47.9386 13.4712 48.3324 12.6842 48.5489C12.0618 48.7124 11.4221 48.7997 10.7789 48.809C10.2141 48.809 9.86272 48.7347 9.72461 48.5861C9.56097 48.3332 9.4891 48.0315 9.52112 47.7317V44.3511L9.74303 44.1282H10.076C10.9268 44.1397 11.7602 43.8867 12.4622 43.4038C13.2754 42.7775 13.8971 41.9344 14.2564 40.9706C14.8389 39.4925 15.2245 37.9437 15.4032 36.3642C15.7038 33.9617 15.8398 31.5414 15.8102 29.1202V26.0741L12.5546 24.5138V23.9194H35.1952V24.5138L31.9767 26.0741V46.2829L35.1952 47.8431V48.4375H21.7293Z"
            fill="#2A201C"
          />
          <path
            d="M41.1162 48.4385V47.8442L44.4088 46.2839V26.0751L41.1162 24.5149V23.9205H54.2863V24.5149L51.4377 26.0751V34.7902H54.3232C54.4859 32.7103 55.1804 30.7078 56.3395 28.9764C57.4723 27.2944 59.0043 25.922 60.7973 24.983C62.6943 23.9998 64.8038 23.5021 66.9384 23.5342C68.667 23.5161 70.3825 23.838 71.9881 24.4815C73.4908 25.0879 74.8608 25.9839 76.0205 27.119C77.1731 28.2554 78.0853 29.6136 78.7026 31.1125C79.3559 32.7152 79.6829 34.433 79.6644 36.1647C79.6837 37.8964 79.3566 39.6144 78.7026 41.2169C78.0872 42.7167 77.1747 44.0754 76.0205 45.2103C74.8617 46.3466 73.4915 47.2428 71.9881 47.8479C70.3825 48.4914 68.667 48.8132 66.9384 48.7952C64.7335 48.8295 62.5562 48.2989 60.6123 47.2535C58.7678 46.257 57.2174 44.7899 56.1175 43C54.9787 41.1392 54.3354 39.0157 54.2492 36.8333H51.4377V46.2691L54.2863 47.8293V48.4237L41.1162 48.4385ZM62.0403 45.645C63.3031 46.3731 64.7495 46.2109 66.3798 45.1583C68.01 44.1058 69.5526 42.3115 71.0078 39.7755C72.0807 37.9812 72.9 36.0458 73.442 34.0249C73.8786 32.2443 73.9612 30.7125 73.6899 29.4296C73.4186 28.1468 72.8242 27.2366 71.9067 26.6992C71.4932 26.4706 71.0364 26.3319 70.566 26.2922C70.0956 26.2525 69.6222 26.3127 69.1766 26.4689C67.9832 26.8753 66.8995 27.5529 66.0098 28.4489C64.7886 29.6458 63.749 31.0164 62.9244 32.5167C61.8474 34.3172 61.0304 36.2621 60.4977 38.2933C60.0636 40.0863 59.9834 41.6267 60.2571 42.9146C60.5272 44.2036 61.1228 45.11 62.0403 45.645Z"
            fill="#2A201C"
          />
          <path
            d="M84.8428 48.4375V47.8431L88.1353 46.2829V26.0741L84.8428 24.5138V23.9194H98.4568V24.5138L95.1643 26.0741V46.2829L98.4568 47.8431V48.4375H84.8428ZM95.1643 36.1784L105.264 25.9255L102.415 24.5138V23.9194H111.738V24.5138L109.185 25.6654L101.379 33.0951L110.739 46.6915L113.551 47.8431V48.4375H103.451L95.1643 36.1784Z"
            fill="#2A201C"
          />
        </svg>
      );
    case "logo-main-white":
      return (
        <svg
          width="124"
          height="58"
          viewBox="0 0 124 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.259 19.2669C16.5997 19.2719 15.9458 19.1457 15.3353 18.8954C14.7588 18.6575 14.2347 18.3078 13.7927 17.8664C13.3509 17.4231 13.0046 16.8933 12.7754 16.3099C12.5266 15.6825 12.4009 15.0126 12.4054 14.3373C12.4005 13.6631 12.5261 12.9944 12.7754 12.3684C13.0046 11.785 13.3509 11.2552 13.7927 10.8119C14.2327 10.3681 14.7574 10.0181 15.3353 9.7829C15.9453 9.53094 16.5995 9.40462 17.259 9.41142C17.9198 9.40432 18.5752 9.53065 19.1864 9.7829C19.7644 10.0181 20.2892 10.3681 20.7292 10.8119C21.172 11.2558 21.5206 11.7854 21.7539 12.3684C22.0062 12.9935 22.132 13.6629 22.1239 14.3373C22.132 15.013 22.0062 15.6835 21.7539 16.3099C21.5206 16.893 21.172 17.4225 20.7292 17.8664C20.2872 18.3078 19.763 18.6575 19.1864 18.8954C18.5747 19.146 17.9196 19.2722 17.259 19.2669ZM17.5476 18.5239C18.0989 18.5362 18.6399 18.3737 19.094 18.0596C19.5529 17.7289 19.9126 17.2778 20.1335 16.7557C20.3946 16.128 20.5207 15.452 20.5035 14.7719C20.5209 13.9301 20.3634 13.0938 20.041 12.3164C19.7753 11.6702 19.3424 11.1072 18.787 10.6856C18.278 10.306 17.66 10.1026 17.026 10.1061C16.4727 10.0959 15.9294 10.2553 15.4686 10.563C15.0004 10.8865 14.6325 11.3358 14.4068 11.8595C14.1427 12.4876 14.0165 13.1655 14.0368 13.847C14.0184 14.692 14.1785 15.5315 14.5067 16.3099C14.7766 16.9588 15.2147 17.5233 15.7756 17.9444C16.2894 18.3331 16.9154 18.5431 17.5587 18.5425L17.5476 18.5239Z"
            fill="#FCFBFA"
          />
          <path
            d="M28.6837 19.2699C27.7897 19.2946 26.9047 19.0846 26.1163 18.6606C25.4136 18.2755 24.8339 17.6985 24.4442 16.9964C24.0422 16.2605 23.8382 15.4321 23.8522 14.5929C23.8479 13.9143 23.9853 13.2423 24.2555 12.6203C24.5239 12.008 24.8998 11.4493 25.3653 10.9709C25.8257 10.4926 26.3716 10.1057 26.9746 9.83041C27.5825 9.55511 28.2424 9.41448 28.9094 9.41806C29.3245 9.41265 29.7394 9.44497 30.1487 9.51464C30.4561 9.56996 30.7581 9.65199 31.0514 9.75982C31.314 9.86012 31.584 9.979 31.8504 10.1127L31.9392 12.6946H31.6802L30.6851 10.8111C30.4091 10.6085 30.1113 10.4378 29.7973 10.3022C29.5138 10.1938 29.2126 10.1396 28.9094 10.1425C28.2738 10.1338 27.6503 10.3162 27.1189 10.6662C26.5807 11.0241 26.1497 11.5224 25.8721 12.1076C25.5579 12.7694 25.4021 13.4958 25.4171 14.2288C25.397 15.0218 25.5472 15.8099 25.8573 16.5394C26.1061 17.1381 26.5192 17.6535 27.0485 18.0254C27.5367 18.3596 28.115 18.5359 28.7059 18.5306C29.1351 18.5423 29.5628 18.4743 29.9674 18.33C30.2901 18.205 30.5978 18.0443 30.8849 17.8508L31.8504 16.2274H32.0983L31.9245 18.5603C31.4888 18.7762 31.0343 18.9516 30.5667 19.0841C29.9499 19.2287 29.3168 19.2911 28.6837 19.2699Z"
            fill="#FCFBFA"
          />
          <path
            d="M35.885 19.1253V18.895L37.3426 18.3155V10.362H35.0637L34.039 12.55H33.8096V9.57812H42.3775V12.55H42.1481L41.1382 10.362H38.8446V18.3155L40.3243 18.895V19.1253H35.885Z"
            fill="#FCFBFA"
          />
          <path
            d="M48.6319 19.2669C47.9713 19.2719 47.3163 19.1457 46.7045 18.8954C46.128 18.6575 45.6038 18.3078 45.1618 17.8664C44.7212 17.4221 44.3751 16.8926 44.1445 16.3099C43.8956 15.6825 43.77 15.0126 43.7746 14.3373C43.7696 13.6631 43.8953 12.9944 44.1445 12.3684C44.3751 11.7857 44.7212 11.2562 45.1618 10.8119C45.6018 10.3681 46.1265 10.0181 46.7045 9.78288C47.3158 9.53092 47.9711 9.4046 48.6319 9.41139C49.2926 9.4046 49.948 9.53092 50.5593 9.78288C51.1363 10.02 51.6606 10.3697 52.1019 10.8119C52.5424 11.2578 52.8906 11.7868 53.1267 12.3684C53.3773 12.994 53.503 13.663 53.4966 14.3373C53.5029 15.0128 53.3772 15.683 53.1267 16.3099C52.8906 16.8915 52.5424 17.4205 52.1019 17.8664C51.6589 18.3065 51.135 18.656 50.5593 18.8954C49.9475 19.1457 49.2925 19.2719 48.6319 19.2669ZM48.9205 18.5239C49.4756 18.5434 50.0225 18.3846 50.4816 18.0707C50.9415 17.741 51.3014 17.2896 51.5212 16.7668C51.7839 16.1396 51.91 15.4632 51.8911 14.7831C51.9097 13.9414 51.7535 13.1052 51.4324 12.3276C51.1641 11.6818 50.73 11.1191 50.1745 10.6967C49.6658 10.3166 49.0477 10.1132 48.4136 10.1172C47.8603 10.1075 47.3172 10.2669 46.8561 10.5741C46.3891 10.8979 46.0225 11.3472 45.7981 11.8706C45.5339 12.4987 45.4077 13.1766 45.4282 13.8581C45.4116 14.703 45.5717 15.5421 45.898 16.321C46.1679 16.97 46.6061 17.5344 47.1669 17.9556C47.6769 18.334 48.2937 18.5396 48.9279 18.5425L48.9205 18.5239Z"
            fill="#FCFBFA"
          />
          <path
            d="M55.1611 19.1237V18.8934L56.3302 18.3139V10.3753L55.1611 9.79574V9.5617H59.201C60.1517 9.51794 61.0964 9.73361 61.9348 10.1858C62.2707 10.3868 62.5448 10.6769 62.7269 11.0243C62.9091 11.3717 62.9921 11.7629 62.967 12.1547C62.97 12.6784 62.7757 13.184 62.4232 13.57C62.0165 14.0101 61.5084 14.3431 60.9434 14.5396C60.2694 14.7864 59.5558 14.906 58.8385 14.8925H57.8285V18.3139L59.1566 18.8934V19.1237H55.1611ZM57.8432 10.3604V14.1273H58.9531C59.5921 14.1647 60.2246 13.9797 60.7436 13.6035C60.949 13.4492 61.1151 13.2482 61.2283 13.0171C61.3415 12.7859 61.3985 12.5311 61.3947 12.2736C61.4186 11.9934 61.3707 11.7118 61.2554 11.4556C61.1401 11.1994 60.9614 10.9771 60.7362 10.8099C60.1667 10.4761 59.5113 10.3197 58.8532 10.3604H57.8432Z"
            fill="#FCFBFA"
          />
          <path
            d="M69.2982 19.2669C68.6388 19.2719 67.9849 19.1457 67.3744 18.8954C66.7979 18.6575 66.2737 18.3078 65.8318 17.8664C65.3899 17.4231 65.0437 16.8933 64.8144 16.3099C64.5656 15.6825 64.44 15.0126 64.4445 14.3373C64.4395 13.6631 64.5652 12.9944 64.8144 12.3684C65.0437 11.785 65.3899 11.2552 65.8318 10.8119C66.2718 10.3681 66.7964 10.0181 67.3744 9.7829C67.9844 9.53094 68.6387 9.40462 69.2982 9.41142C69.959 9.40432 70.6144 9.53065 71.2256 9.7829C71.8042 10.019 72.3299 10.3689 72.7719 10.8119C73.2125 11.257 73.5597 11.7862 73.793 12.3684C74.0453 12.9935 74.1711 13.6629 74.1629 14.3373C74.171 15.013 74.0453 15.6835 73.793 16.3099C73.5597 16.8921 73.2125 17.4214 72.7719 17.8664C72.3279 18.307 71.8027 18.6565 71.2256 18.8954C70.6139 19.146 69.9588 19.2722 69.2982 19.2669ZM69.5904 18.5239C70.1404 18.5357 70.6802 18.3732 71.1331 18.0596C71.5921 17.7289 71.9516 17.2778 72.1726 16.7557C72.4337 16.128 72.5597 15.452 72.5425 14.7719C72.56 13.9301 72.4025 13.0938 72.0801 12.3164C71.8144 11.6702 71.3814 11.1072 70.826 10.6856C70.3171 10.306 69.6991 10.1026 69.0651 10.1061C68.5118 10.0964 67.9687 10.2557 67.5076 10.563C67.0395 10.8865 66.6715 11.3358 66.4458 11.8595C66.1817 12.4876 66.0556 13.1655 66.0759 13.847C66.0574 14.692 66.2176 15.5315 66.5457 16.3099C66.8156 16.9588 67.2539 17.5233 67.8147 17.9444C68.3265 18.3316 68.9496 18.5414 69.5904 18.5425V18.5239Z"
            fill="#FCFBFA"
          />
          <path
            d="M81.4057 14.2437L77.2216 19.125H75.002V18.8947L75.9416 18.5232L79.9666 13.8982L76.3744 10.2205L75.2905 9.8156V9.58157H78.62V9.8156L77.5805 10.3208L81.4057 14.2437ZM80.2663 19.125V18.8947L81.4204 18.3152V10.3765L80.2663 9.79703V9.56299H84.073V9.79703L82.9187 10.3765V18.3152L84.073 18.8947V19.125H80.2663ZM82.9335 14.2437L86.7588 10.3022L85.7192 9.79703V9.56299H88.9637V9.79703L87.8835 10.2019L84.3764 13.7793L88.5604 18.5158L89.4557 18.8873V19.1176H87.1065L82.9335 14.2437Z"
            fill="#FCFBFA"
          />
          <path
            d="M90.3774 19.125V18.8947L91.5464 18.3152V10.3765L90.3774 9.79703V9.56299H94.2026V9.79703L93.0632 10.3765V13.7942H97.7504V10.3765L96.5961 9.79703V9.56299H100.425V9.79703L99.2561 10.3765V18.3152L100.425 18.8947V19.125H96.5998V18.8947L97.7541 18.3152V14.6003H93.0669V18.3152L94.2063 18.8947V19.125H90.3774Z"
            fill="#FCFBFA"
          />
          <path
            d="M106.929 19.2669C106.268 19.2722 105.613 19.146 105.001 18.8954C104.425 18.6575 103.901 18.3078 103.459 17.8664C103.018 17.4221 102.672 16.8926 102.441 16.3099C102.193 15.6825 102.067 15.0126 102.071 14.3373C102.066 13.6632 102.192 12.9944 102.441 12.3684C102.672 11.7858 103.018 11.2562 103.459 10.8119C103.899 10.3681 104.423 10.0181 105.001 9.7829C105.613 9.53065 106.268 9.40432 106.929 9.41142C107.588 9.40431 108.243 9.53065 108.853 9.7829C109.431 10.019 109.957 10.3689 110.399 10.8119C110.839 11.2578 111.188 11.7868 111.424 12.3684C111.674 12.994 111.8 13.663 111.794 14.3373C111.8 15.0128 111.674 15.683 111.424 16.3099C111.188 16.8915 110.839 17.4205 110.399 17.8664C109.955 18.307 109.43 18.6565 108.853 18.8954C108.242 19.146 107.588 19.2723 106.929 19.2669ZM107.217 18.524C107.767 18.5357 108.307 18.3732 108.76 18.0596C109.22 17.7298 109.58 17.2785 109.8 16.7557C110.062 16.1285 110.188 15.4521 110.17 14.7719C110.188 13.9303 110.032 13.0941 109.711 12.3164C109.443 11.6707 109.008 11.108 108.453 10.6856C107.944 10.3055 107.326 10.1021 106.692 10.1061C106.139 10.0964 105.596 10.2557 105.135 10.563C104.666 10.8865 104.299 11.3358 104.073 11.8595C103.811 12.4881 103.684 13.1656 103.703 13.847C103.684 14.692 103.845 15.5315 104.173 16.3099C104.443 16.9588 104.881 17.5233 105.442 17.9444C105.953 18.3316 106.577 18.5414 107.217 18.5425V18.524Z"
            fill="#FCFBFA"
          />
          <path
            d="M21.7293 48.4375V47.8431L24.9478 46.2829V25.9626H18.3998V29.0088C18.3998 32.4512 18.3135 35.225 18.1408 37.3301C18.0334 38.9639 17.8233 40.5893 17.5119 42.1965C17.3549 43.0504 17.1069 43.8849 16.772 44.6855C16.3155 45.7178 15.6475 46.6418 14.8113 47.3973C14.1987 47.9386 13.4712 48.3324 12.6842 48.5489C12.0618 48.7124 11.4221 48.7997 10.7789 48.809C10.2141 48.809 9.86272 48.7347 9.72461 48.5861C9.56097 48.3332 9.4891 48.0315 9.52112 47.7317V44.3511L9.74303 44.1282H10.076C10.9268 44.1397 11.7602 43.8867 12.4622 43.4038C13.2754 42.7775 13.8971 41.9344 14.2564 40.9706C14.8389 39.4925 15.2245 37.9437 15.4032 36.3642C15.7038 33.9617 15.8398 31.5414 15.8102 29.1202V26.0741L12.5546 24.5138V23.9194H35.1952V24.5138L31.9767 26.0741V46.2829L35.1952 47.8431V48.4375H21.7293Z"
            fill="#FCFBFA"
          />
          <path
            d="M41.1162 48.4385V47.8442L44.4088 46.2839V26.0751L41.1162 24.5149V23.9205H54.2863V24.5149L51.4377 26.0751V34.7902H54.3232C54.4859 32.7103 55.1804 30.7078 56.3395 28.9764C57.4723 27.2944 59.0043 25.922 60.7973 24.983C62.6943 23.9998 64.8038 23.5021 66.9384 23.5342C68.667 23.5161 70.3825 23.838 71.9881 24.4815C73.4908 25.0879 74.8608 25.9839 76.0205 27.119C77.1731 28.2554 78.0853 29.6136 78.7026 31.1125C79.3559 32.7152 79.6829 34.433 79.6644 36.1647C79.6837 37.8964 79.3566 39.6144 78.7026 41.2169C78.0872 42.7167 77.1747 44.0754 76.0205 45.2103C74.8617 46.3466 73.4915 47.2428 71.9881 47.8479C70.3825 48.4914 68.667 48.8132 66.9384 48.7952C64.7335 48.8295 62.5562 48.2989 60.6123 47.2535C58.7678 46.257 57.2174 44.7899 56.1175 43C54.9787 41.1392 54.3354 39.0157 54.2492 36.8333H51.4377V46.2691L54.2863 47.8293V48.4237L41.1162 48.4385ZM62.0403 45.645C63.3031 46.3731 64.7495 46.2109 66.3798 45.1583C68.01 44.1058 69.5526 42.3115 71.0078 39.7755C72.0807 37.9812 72.9 36.0458 73.442 34.0249C73.8786 32.2443 73.9612 30.7125 73.6899 29.4296C73.4186 28.1468 72.8242 27.2366 71.9067 26.6992C71.4932 26.4706 71.0364 26.3319 70.566 26.2922C70.0956 26.2525 69.6222 26.3127 69.1766 26.4689C67.9832 26.8753 66.8995 27.5529 66.0098 28.4489C64.7886 29.6458 63.749 31.0164 62.9244 32.5167C61.8474 34.3172 61.0304 36.2621 60.4977 38.2933C60.0636 40.0863 59.9834 41.6267 60.2571 42.9146C60.5272 44.2036 61.1228 45.11 62.0403 45.645Z"
            fill="#FCFBFA"
          />
          <path
            d="M84.8428 48.4375V47.8431L88.1353 46.2829V26.0741L84.8428 24.5138V23.9194H98.4568V24.5138L95.1643 26.0741V46.2829L98.4568 47.8431V48.4375H84.8428ZM95.1643 36.1784L105.264 25.9255L102.415 24.5138V23.9194H111.738V24.5138L109.185 25.6654L101.379 33.0951L110.739 46.6915L113.551 47.8431V48.4375H103.451L95.1643 36.1784Z"
            fill="#FCFBFA"
          />
        </svg>
      );
    case "logo-white":
      return (
        <svg
          width="166"
          height="53"
          viewBox="0 0 166 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M77.4143 17.3096C76.7847 17.3096 76.1989 17.1995 75.6603 16.9792C75.1216 16.759 74.6536 16.4486 74.2563 16.0548C73.8591 15.6609 73.5493 15.1937 73.3305 14.6564C73.1116 14.119 73.0039 13.5283 73.0039 12.8875C73.0039 12.2467 73.1116 11.656 73.3305 11.1186C73.5493 10.5813 73.8591 10.114 74.2563 9.72022C74.6536 9.3264 75.1216 9.01602 75.6603 8.79574C76.1989 8.57547 76.7814 8.46533 77.4143 8.46533C78.0473 8.46533 78.6297 8.57547 79.1684 8.79574C79.7071 9.01602 80.175 9.3264 80.5723 9.72022C80.9696 10.114 81.2827 10.5813 81.5049 11.1186C81.7271 11.656 81.8416 12.2467 81.8416 12.8875C81.8416 13.5283 81.7305 14.119 81.5049 14.6564C81.2827 15.1937 80.9696 15.6609 80.5723 16.0548C80.175 16.4486 79.7071 16.759 79.1684 16.9792C78.6297 17.1995 78.0473 17.3096 77.4143 17.3096ZM77.6769 16.6588C78.2122 16.6588 78.6802 16.5187 79.0808 16.2417C79.4848 15.9646 79.798 15.5742 80.0269 15.0702C80.2558 14.5662 80.3669 13.9722 80.3669 13.288C80.3669 12.4469 80.2255 11.7127 79.9461 11.0819C79.6667 10.4545 79.2862 9.96386 78.8048 9.61676C78.3233 9.26966 77.788 9.09612 77.2022 9.09612C76.6669 9.09612 76.1956 9.23295 75.7848 9.50662C75.3741 9.7803 75.0509 10.1674 74.8186 10.6714C74.5863 11.1754 74.4718 11.7694 74.4718 12.4536C74.4718 13.2947 74.6132 14.0322 74.8994 14.6664C75.1822 15.3005 75.5694 15.7911 76.0542 16.1382C76.539 16.4853 77.081 16.6588 77.6769 16.6588Z"
            fill="#FCFBFA"
          />
          <path
            d="M87.8169 17.31C86.9146 17.31 86.1369 17.1264 85.4804 16.7626C84.8238 16.3988 84.3155 15.8982 83.9586 15.2641C83.5984 14.63 83.4199 13.9091 83.4199 13.1048C83.4199 12.4806 83.5411 11.8899 83.7869 11.3359C84.0327 10.7819 84.366 10.2846 84.7902 9.85071C85.2144 9.41684 85.7026 9.07642 86.2547 8.82944C86.8069 8.58247 87.3927 8.45898 88.0155 8.45898C88.4633 8.45898 88.837 8.48568 89.1434 8.54242C89.4497 8.59916 89.7224 8.67258 89.9648 8.76269C90.2039 8.85281 90.4496 8.95961 90.6921 9.08309L90.7695 11.3993H90.5338L89.6282 9.7072C89.3319 9.50028 89.0626 9.34675 88.8202 9.24997C88.5777 9.15318 88.3084 9.10645 88.0121 9.10645C87.4095 9.10645 86.8641 9.26332 86.3826 9.5737C85.9012 9.88743 85.5241 10.318 85.2481 10.8686C84.972 11.4193 84.834 12.0568 84.834 12.7743C84.834 13.582 84.9686 14.2729 85.2346 14.8503C85.5006 15.4276 85.8642 15.8682 86.3187 16.1719C86.7732 16.4756 87.2782 16.6291 87.827 16.6291C88.2815 16.6291 88.6653 16.5691 88.975 16.4456C89.2848 16.3254 89.5642 16.1819 89.81 16.015L90.6887 14.5566H90.9109L90.7527 16.6525C90.3688 16.8427 89.9547 16.9996 89.5171 17.1197C89.0794 17.2399 88.5104 17.3033 87.8101 17.3033L87.8169 17.31Z"
            fill="#FCFBFA"
          />
          <path
            d="M94.3653 17.1786V16.9717L95.6918 16.4511V9.30553H93.6179L92.6853 11.2713H92.4766V8.59131H100.277V11.2713H100.069L99.1494 9.30553H97.062V16.4511L98.402 16.9717V17.1786H94.372H94.3653Z"
            fill="#FCFBFA"
          />
          <path
            d="M105.975 17.3096C105.345 17.3096 104.759 17.1995 104.221 16.9792C103.682 16.759 103.214 16.4486 102.817 16.0548C102.42 15.6609 102.11 15.1937 101.891 14.6564C101.672 14.119 101.564 13.5283 101.564 12.8875C101.564 12.2467 101.672 11.656 101.891 11.1186C102.11 10.5813 102.42 10.114 102.817 9.72022C103.214 9.3264 103.682 9.01602 104.221 8.79574C104.759 8.57547 105.342 8.46533 105.975 8.46533C106.608 8.46533 107.19 8.57547 107.729 8.79574C108.268 9.01602 108.736 9.3264 109.133 9.72022C109.53 10.114 109.843 10.5813 110.065 11.1186C110.288 11.656 110.399 12.2467 110.399 12.8875C110.399 13.5283 110.288 14.119 110.065 14.6564C109.843 15.1937 109.53 15.6609 109.133 16.0548C108.736 16.4486 108.268 16.759 107.729 16.9792C107.19 17.1995 106.608 17.3096 105.975 17.3096ZM106.237 16.6588C106.773 16.6588 107.241 16.5187 107.641 16.2417C108.045 15.9646 108.359 15.5742 108.587 15.0702C108.816 14.5662 108.927 13.9722 108.927 13.288C108.927 12.4469 108.786 11.7127 108.507 11.0819C108.227 10.4545 107.847 9.96386 107.365 9.61676C106.884 9.26966 106.349 9.09612 105.763 9.09612C105.227 9.09612 104.756 9.23295 104.345 9.50662C103.935 9.7803 103.611 10.1674 103.379 10.6714C103.147 11.1754 103.032 11.7694 103.032 12.4536C103.032 13.2947 103.174 14.0322 103.46 14.6664C103.743 15.3005 104.13 15.7911 104.615 16.1382C105.1 16.4853 105.642 16.6588 106.237 16.6588Z"
            fill="#FCFBFA"
          />
          <path
            d="M111.908 17.1786V16.9717L112.972 16.4511V9.31888L111.908 8.79823V8.59131H115.585C116.618 8.59131 117.446 8.77821 118.073 9.152C118.699 9.5258 119.012 10.1165 119.012 10.9209C119.012 11.3981 118.847 11.822 118.52 12.1891C118.194 12.5562 117.749 12.8499 117.187 13.0602C116.628 13.2738 115.989 13.3806 115.272 13.3806H114.352V16.4511L115.561 16.9717V17.1786H111.912H111.908ZM114.349 9.30553V12.6897H115.346C116.036 12.6897 116.581 12.5329 116.975 12.2225C117.369 11.9088 117.568 11.5116 117.568 11.0243C117.568 10.4169 117.369 9.9797 116.968 9.70936C116.568 9.43903 115.999 9.30553 115.255 9.30553H114.349Z"
            fill="#FCFBFA"
          />
          <path
            d="M124.774 17.3096C124.144 17.3096 123.558 17.1995 123.02 16.9792C122.481 16.759 122.013 16.4486 121.616 16.0548C121.218 15.6609 120.909 15.1937 120.69 14.6564C120.471 14.119 120.363 13.5283 120.363 12.8875C120.363 12.2467 120.471 11.656 120.69 11.1186C120.909 10.5813 121.218 10.114 121.616 9.72022C122.013 9.3264 122.481 9.01602 123.02 8.79574C123.558 8.57547 124.141 8.46533 124.774 8.46533C125.407 8.46533 125.989 8.57547 126.528 8.79574C127.066 9.01602 127.534 9.3264 127.932 9.72022C128.329 10.114 128.642 10.5813 128.864 11.1186C129.086 11.656 129.201 12.2467 129.201 12.8875C129.201 13.5283 129.09 14.119 128.864 14.6564C128.642 15.1937 128.329 15.6609 127.932 16.0548C127.534 16.4486 127.066 16.759 126.528 16.9792C125.989 17.1995 125.407 17.3096 124.774 17.3096ZM125.04 16.6588C125.575 16.6588 126.043 16.5187 126.444 16.2417C126.844 15.9646 127.161 15.5742 127.39 15.0702C127.619 14.5662 127.73 13.9722 127.73 13.288C127.73 12.4469 127.588 11.7127 127.309 11.0819C127.029 10.4545 126.649 9.96386 126.168 9.61676C125.686 9.26966 125.151 9.09612 124.565 9.09612C124.03 9.09612 123.558 9.23295 123.148 9.50662C122.737 9.7803 122.414 10.1674 122.181 10.6714C121.949 11.1754 121.835 11.7694 121.835 12.4536C121.835 13.2947 121.976 14.0322 122.262 14.6664C122.545 15.3005 122.932 15.7911 123.417 16.1382C123.902 16.4853 124.444 16.6588 125.04 16.6588Z"
            fill="#FCFBFA"
          />
          <path
            d="M135.796 12.7941L131.988 17.1795H129.965V16.9726L130.82 16.6222L134.483 12.4704L131.214 9.16291L130.227 8.79913V8.59221H133.261V8.79913L132.315 9.25636L135.796 12.7974V12.7941ZM134.759 17.1795V16.9726L135.809 16.452V9.31977L134.759 8.79913V8.59221H138.227V8.79913L137.176 9.31977V16.452L138.227 16.9726V17.1795H134.759ZM137.186 12.7941L140.668 9.25303L139.722 8.79579V8.58887H142.678V8.79579L141.691 9.15958L138.499 12.3736L142.307 16.6289L143.122 16.9659V17.1729H140.994L137.186 12.7874V12.7941Z"
            fill="#FCFBFA"
          />
          <path
            d="M143.959 17.1786V16.9717L145.023 16.4511V9.31888L143.959 8.79823V8.59131H147.44V8.79823L146.403 9.31888V12.3894H150.672V9.31888L149.622 8.79823V8.59131H153.1V8.79823L152.036 9.31888V16.4511L153.1 16.9717V17.1786H149.622V16.9717L150.672 16.4511V13.1069H146.403V16.4511L147.44 16.9717V17.1786H143.959Z"
            fill="#FCFBFA"
          />
          <path
            d="M159.02 17.3096C158.39 17.3096 157.804 17.1995 157.266 16.9792C156.727 16.759 156.259 16.4486 155.862 16.0548C155.465 15.6609 155.155 15.1937 154.936 14.6564C154.717 14.119 154.609 13.5283 154.609 12.8875C154.609 12.2467 154.717 11.656 154.936 11.1186C155.155 10.5813 155.465 10.114 155.862 9.72022C156.259 9.3264 156.727 9.01602 157.266 8.79574C157.804 8.57547 158.387 8.46533 159.02 8.46533C159.653 8.46533 160.235 8.57547 160.774 8.79574C161.313 9.01602 161.781 9.3264 162.178 9.72022C162.575 10.114 162.888 10.5813 163.11 11.1186C163.333 11.656 163.444 12.2467 163.444 12.8875C163.444 13.5283 163.333 14.119 163.11 14.6564C162.888 15.1937 162.575 15.6609 162.178 16.0548C161.781 16.4486 161.313 16.759 160.774 16.9792C160.235 17.1995 159.649 17.3096 159.02 17.3096ZM159.282 16.6588C159.818 16.6588 160.286 16.5187 160.686 16.2417C161.09 15.9646 161.403 15.5742 161.632 15.0702C161.861 14.5662 161.972 13.9722 161.972 13.288C161.972 12.4469 161.831 11.7127 161.552 11.0819C161.272 10.4545 160.892 9.96386 160.41 9.61676C159.929 9.26966 159.393 9.09612 158.808 9.09612C158.272 9.09612 157.801 9.23295 157.39 9.50662C156.98 9.7803 156.656 10.1674 156.424 10.6714C156.192 11.1754 156.077 11.7694 156.077 12.4536C156.077 13.2947 156.219 14.0322 156.505 14.6664C156.788 15.3005 157.175 15.7911 157.66 16.1382C158.144 16.4853 158.686 16.6588 159.282 16.6588Z"
            fill="#FCFBFA"
          />
          <path
            d="M81.4852 43.5166V42.9826L84.4142 41.5809V23.3249H78.4551V26.0616C78.4551 29.1554 78.3777 31.6452 78.2195 33.5376C78.0612 35.4299 77.8727 36.8884 77.6471 37.9096C77.4216 38.9343 77.1994 39.6785 76.9738 40.1458C76.4351 41.2371 75.8392 42.0481 75.1894 42.5821C74.5396 43.1161 73.8932 43.4599 73.2535 43.6167C72.6139 43.7736 72.0348 43.8504 71.5197 43.8504C71.0046 43.8504 70.6847 43.7836 70.5602 43.6501C70.4356 43.5166 70.375 43.2596 70.375 42.8825V39.8454L70.577 39.6451H70.88C71.688 39.6451 72.4119 39.4282 73.0515 38.9943C73.6912 38.5605 74.2366 37.8329 74.6844 36.8083C75.1322 35.7837 75.4823 34.4053 75.7281 32.6698C75.9739 30.9343 76.0984 28.765 76.0984 26.1617V23.425L73.1357 22.0233V21.4893H93.7401V22.0233L90.811 23.425V41.5809L93.7401 42.9826V43.5166H81.4852Z"
            fill="#FCFBFA"
          />
          <path
            d="M99.127 43.5169V42.9829L102.123 41.5811V23.4252L99.127 22.0235V21.4895H111.113V22.0235L108.52 23.4252V31.2683H111.146C111.325 29.3092 111.937 27.5704 112.981 26.0452C114.025 24.5199 115.378 23.3251 117.038 22.4574C118.698 21.5896 120.563 21.1558 122.627 21.1558C124.286 21.1558 125.818 21.4394 127.222 22.0068C128.626 22.5742 129.848 23.3652 130.892 24.3764C131.936 25.3877 132.75 26.5858 133.333 27.9642C133.915 29.3426 134.208 30.8578 134.208 32.5032C134.208 34.1486 133.915 35.6638 133.333 37.0422C132.75 38.4205 131.936 39.6187 130.892 40.6299C129.848 41.6445 128.626 42.4322 127.222 42.9996C125.818 43.5669 124.286 43.8506 122.627 43.8506C120.496 43.8506 118.577 43.39 116.87 42.4656C115.163 41.5411 113.799 40.2695 112.779 38.6441C111.759 37.0188 111.19 35.1732 111.079 33.1039H108.52V41.5811L111.113 42.9829V43.5169H99.127ZM118.169 41.0071C119.317 41.6646 120.634 41.5177 122.118 40.5699C123.603 39.622 125.007 38.01 126.333 35.7372C127.411 33.8882 128.148 32.1661 128.545 30.5674C128.943 28.9721 129.02 27.5938 128.771 26.439C128.525 25.2842 127.983 24.4665 127.148 23.9859C126.428 23.5754 125.6 23.5053 124.664 23.779C123.728 24.0527 122.765 24.6468 121.782 25.5579C120.795 26.4724 119.859 27.6905 118.974 29.2124C117.896 31.0614 117.159 32.7935 116.765 34.4055C116.371 36.0176 116.297 37.3993 116.543 38.5574C116.789 39.7121 117.331 40.5298 118.166 41.0104L118.169 41.0071Z"
            fill="#FCFBFA"
          />
          <path
            d="M138.918 43.5166V42.9826L141.914 41.5809V23.425L138.918 22.0233V21.4893H151.308V22.0233L148.311 23.425V41.5809L151.308 42.9826V43.5166H138.918ZM148.311 32.5029L157.502 23.2915L154.91 22.0233V21.4893H163.394V22.0233L161.071 23.0579L153.967 29.7328L162.485 41.948L165.044 42.9826V43.5166H155.853L148.311 32.5029Z"
            fill="#FCFBFA"
          />
          <path
            d="M0.15487 23.2188C0.56561 18.7232 1.96954 14.725 4.36664 11.2273C6.76375 7.7296 9.86788 4.98285 13.6824 2.99038C17.4969 0.997906 21.7726 0 26.513 0C30.3275 0 33.8423 0.650808 37.0643 1.95242C40.2829 3.25404 43.0941 5.06963 45.4912 7.39251C47.8883 9.71873 49.7535 12.4621 51.0968 15.6294C52.4368 18.7967 53.1068 22.271 53.1068 26.0524C53.1068 29.8337 52.4368 33.308 51.0968 36.4753C49.7569 39.6426 47.8883 42.3893 45.4912 44.7122C43.0941 47.0384 40.2863 48.8507 37.0643 50.1523C33.8423 51.4539 30.3241 52.1047 26.513 52.1047C21.6178 52.1047 17.2107 51.0434 13.2952 48.9241C9.37634 46.8048 6.24865 43.8812 3.90204 40.1499C1.55543 36.4219 0.255871 32.18 0 27.4274M16.2781 45.5766C18.9109 47.0851 21.9342 46.748 25.3447 44.572C28.7552 42.396 31.9805 38.6947 35.0207 33.4749C37.4952 29.2263 39.1887 25.2714 40.1045 21.6068C41.0202 17.9423 41.1919 14.7817 40.6263 12.1284C40.0607 9.4751 38.815 7.5961 36.8993 6.49807C35.2463 5.55356 33.3474 5.39336 31.1961 6.02081C29.0448 6.64826 26.8395 8.00995 24.5771 10.1059C22.3147 12.2018 20.1633 14.9986 18.1298 18.493C15.6553 22.7416 13.9652 26.7132 13.0629 30.4144C12.1573 34.1157 11.9889 37.293 12.5545 39.9463C13.1201 42.5996 14.3625 44.4752 16.2815 45.5766H16.2781Z"
            fill="#FCFBFA"
          />
          <path
            d="M49.5389 30.8107C49.118 29.2588 48.0743 28.0306 46.4011 27.1295C44.7278 26.225 42.5765 25.7311 39.9471 25.6409C39.5801 25.6276 39.2064 25.6276 38.826 25.6343C37.8833 28.1107 36.6241 30.7072 35.0417 33.4206C32.0016 38.6404 28.7763 42.3417 25.3658 44.5177C21.9553 46.6971 18.9353 47.0308 16.2992 45.5223C15.4743 45.0484 14.7808 44.4243 14.2051 43.6633C15.2084 45.2086 16.9826 46.2565 19.5279 46.8006C22.8946 47.5248 27.0761 47.2211 32.0656 45.8961C36.1258 44.8181 39.5397 43.4497 42.3105 41.7943C45.0813 40.1356 47.098 38.3434 48.3673 36.411C49.6365 34.4786 50.0271 32.6096 49.5389 30.8107Z"
            fill="#F75307"
          />
        </svg>
      );
  }
}
